import React from "react";
import styles from "./redirect.module.scss";

const Redirect = () => (
  <section className={styles.container}>
    <div className={styles.inner}>
      <h1 className={styles.title}>Redirecting...</h1>
    </div>
  </section>
);

export default Redirect;
